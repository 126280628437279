<template>
  <div>
    <v-select
      outlined
      v-model="ageSlot"
      :items="ageSlots"
      clearable
      item-text="name"
      item-value="name"
      :menu-props="{ maxHeight: '400', zIndex: 13 }"
      label="Tranche d'âge"
    ></v-select>
  </div>
</template>

<script>
import { URL_PARAM_NAMES } from "@/utils/constants";
import {
  addQueryStringParam,
  synchronizeFilterWithQueryString,
} from "@/utils/http";

export default {
  name: "AgeFilter",
  data() {
    return {
      ageSlots: [
        {
          name: "18-24",
        },
        {
          name: "25-34",
        },
        {
          name: "35-44",
        },
        {
          name: "45-54",
        },
        {
          name: "55-64",
        },
        {
          name: "65+",
        },
      ],
    };
  },
  async created() {
    await synchronizeFilterWithQueryString({
      router: this.$router,
      route: this.$route,
      store: this.$store,
      dispatcher: "marmiton/updateAgeSlot",
      param: URL_PARAM_NAMES[this.$options.name],
      value: this.ageSlot,
      // is_multiple: false,
      // is_integer: false,
      // is_boolean: false,
      // dependsOn: undefined,
    });
  },
  computed: {
    ageSlot: {
      get() {
        return this.$store.getters["marmiton/getAgeSlot"];
      },
      set(e) {
        this.$store.dispatch("marmiton/updateAgeSlot", e);
      },
    },
  },
  watch: {
    ageSlot(newValue) {
      addQueryStringParam({
        router: this.$router,
        route: this.$route,
        param: URL_PARAM_NAMES[this.$options.name],
        value: newValue,
        // is_multiple: false,
        // is_integer: false,
        // is_boolean: false,
      });
    },
  },
};
</script>

<style></style>
