<template>
  <div>
    <v-select
      outlined
      v-model="region"
      :items="regions"
      clearable
      item-text="name"
      item-value="id"
      :menu-props="{ maxHeight: '400', zIndex: 13 }"
      label="Région"
    ></v-select>
  </div>
</template>

<script>
import { URL_PARAM_NAMES } from "@/utils/constants";
import {
  addQueryStringParam,
  synchronizeFilterWithQueryString,
} from "@/utils/http";

export default {
  name: "RegionFilter",
  data() {
    return {
      regions: [
        {
          id: "Auvergne-Rhone-Alpes",
          name: "Auvergne-Rhone-Alpes",
        },
        {
          id: "Bourgogne-Franche-Comte",
          name: "Bourgogne-Franche-Comte",
        },
        {
          id: "Brittany",
          name: "Bretagne",
        },
        {
          id: "Centre-Val de Loire",
          name: "Centre-Val de Loire",
        },
        {
          id: "Corsica",
          name: "Corse",
        },
        {
          id: "Grand Est",
          name: "Grand Est",
        },
        {
          id: "Hauts-de-France",
          name: "Hauts-de-France",
        },
        {
          id: "Ile-de-France",
          name: "Ile-de-France",
        },
        {
          id: "Normandy",
          name: "Normandie",
        },
        {
          id: "Nouvelle-Aquitaine",
          name: "Nouvelle-Aquitaine",
        },
        {
          id: "Occitanie",
          name: "Occitanie",
        },
        {
          id: "Pays de la Loire",
          name: "Pays de la Loire",
        },
        {
          id: "Provence-Alpes-Cote d'Azur",
          name: "Provence-Alpes-Cote d'Azur",
        },
        // {
        //   name: "Autres",
        // },
      ],
    };
  },
  async created() {
    await synchronizeFilterWithQueryString({
      router: this.$router,
      route: this.$route,
      store: this.$store,
      dispatcher: "marmiton/updateRegion",
      param: URL_PARAM_NAMES[this.$options.name],
      value: this.region,
      // is_multiple: false,
      // is_integer: false,
      // is_boolean: false,
      // dependsOn: undefined,
    });
  },
  computed: {
    region: {
      get() {
        return this.$store.getters["marmiton/getRegion"];
      },
      set(e) {
        this.$store.dispatch("marmiton/updateRegion", e);
      },
    },
  },
  watch: {
    region(newValue) {
      addQueryStringParam({
        router: this.$router,
        route: this.$route,
        param: URL_PARAM_NAMES[this.$options.name],
        value: newValue,
        // is_multiple: false,
        // is_integer: false,
        // is_boolean: false,
      });
    },
  },
};
</script>

<style></style>
